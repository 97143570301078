module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QG1PGCJCP5"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,500&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ajamal Khan","short_name":"Ajamal Khan","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/images/favicon.png","icons":[{"purpose":"any","src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"purpose":"any","src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"purpose":"any","src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"purpose":"any","src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"purpose":"any","src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"purpose":"maskable","src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"purpose":"maskable","src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"purpose":"maskable","src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"purpose":"maskable","src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"purpose":"maskable","src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"07036ed906fe80a759d30177e2852475"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
